import  {Component} from 'react'
export default class WhatWeDo extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
          data: this.props.data
        }
      }
    render (){
        const post = this.state.data;
        let new_data=post.acf.page_239;
        return(
            <div>
                <div className="container-fluid">
                    <div className=" row hero-banner">
                        {
                                new_data.what_we_do_banner && new_data.what_we_do_banner.sizes["2048x2048"] &&
                                <img  src={new_data.what_we_do_banner.sizes["2048x2048"]} className="img-fluid" alt="what_we_do_banner"/>
                        }
                        <div className="overlay">
                            <div className="container-fluid"></div>
                                {/* <h1 className="text-uppercase text-md-right">Explore the <br /> potential</h1> */}
                                <h1 className="text-uppercase text-md-right" dangerouslySetInnerHTML={{__html: new_data.what_we_do_banner_text}}></h1>
                            </div>
                        </div>
                    </div>
                    <section className="section ">
                        <div className="container-fluid">
                            <h2 className="text-uppercase text-center mb-4" dangerouslySetInnerHTML={{__html: post.acf.page_51.taxonomy_title}}></h2>
                            <div className="row">
                            {post.Skills.map((skill ,ii)=>skill.map((arr , jj)=>(
                                <div key={jj} className="col-md-4 col-sm-6">
                                    <div className="job-item job-item-alt pulse">
                                        <a href={arr[2]} className="job-item-inner">
                                        <span>
                                        {
                                                arr[0] && arr[0].sizes && arr[0].sizes.thumbnail &&
                                                <img  src={arr[0].sizes.thumbnail}  alt="thumbnail"/>
                                        }
                                        </span>
                                        <h6>{arr[1].name}</h6>
                                        <p className="text-muted">{arr[1].description}</p>
                                        </a>
                                    </div>
                                </div>
                            )))}
                            </div>
                        </div>
                    </section>
                </div>

        )
    }
}