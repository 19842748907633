import '../App.css';
import  {Component} from 'react';
import Testimonials from "./testimonial.js";
import Contact from "./contact.js";

export default class Home extends Component {
    state = {data: this.props.data}
        render() {
            const post = this.state.data;
            let new_data=post.acf.page_51;

              return (
                <div >
                     <div className="container-fluid">
                            <div className=" row hero-banner">
                                {
                                    new_data.banner && new_data.banner.sizes["2048x2048"] &&
                                    <img src={new_data.banner.sizes["2048x2048"]} className="img-fluid"  alt="home-banner"  />
                                }
                                <div className="overlay">
                                        <h1 dangerouslySetInnerHTML={{__html: new_data.banner_text}}></h1>
                                        {
                                            new_data.banner_button_link &&
                                            <a href={new_data.banner_button_link} className="btn btn-th">{new_data.banner_button_text}</a>
                                        }
                               </div>
                            </div>
                        </div>
                    <div className="container-fluid section">
                        <h2 className="text-uppercase text-center mb-4" dangerouslySetInnerHTML={{__html: new_data.taxonomy_title}} ></h2>
                        {/* <h2 className="text-uppercase text-center mb-4">Specialized in <span className="text-blue">IT</span> Recruitment</h2> */}
                        <div className="jobs-list">
                             {post.Skills.map((skill ,ii)=>skill.map((arr , jj)=>(
                                    <div key={jj} className="job-item pulse">
                                        <a href="!#" className="job-item-inner" >
                                        <span>{
                                                arr[0] && arr[0].sizes && arr[0].sizes.thumbnail &&
                                                <img src={arr[0].sizes.thumbnail} className="img-fluid"  alt="skill"  />
                                            }
                                </span>
                                        <h6 className="skills">{arr[1].name}</h6>
                                    </a>
                                
                            </div>
                             )))}
                            </div>
                    </div>
                    <section className="text-center about-section">
                        <div className="container">
                            <h2 className="text-uppercase text-center mb-4">{new_data.expertise_title}</h2>
                            <p>{new_data.expertise_you_can_trust}</p>
                            <p className="mb-5">
                                { new_data.linkedin &&
                                <a href={new_data.linkedin}>
                                {
                                    new_data.linkedin_image && new_data.linkedin_image.sizes["post-thumbnail"] &&
                                    <img src={new_data.linkedin_image.sizes["post-thumbnail"]} className="img-fluid"  alt="linkedin"  />
                                }
                            </a>
                            }</p> 
                        </div>
                    </section>
                    
                    <div className="testimonials">
                        <h2 className="text-light mb-3">{new_data.testimonial_title}</h2>
                        <Testimonials data={post} />
                    </div>
                            
                    <div className="contact-section sect ">
                        <div className="container-fluid">
                            <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <h2 className="mb-4">{new_data.contact_form_title}</h2>
                                    <Contact />
                                    </div>
                                    <div className="col-md-4 d-none d-md-block">
                                        {
                                           new_data.contact_us_form_image && new_data.contact_us_form_image.sizes["medium_large"] &&
                                            <img height="350px" src={new_data.contact_us_form_image.sizes["medium_large"]} className="img-fluid" alt="contact"/>
                                        }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        
                )
                
            }   
    }