import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "jquery/dist/jquery.min.js";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
<link id="favicon" rel="icon" href="./public/logo192.png"/>

// ReactDOM.render(
//     <div className="home">
//     <App />
//     </div>
//   ,
//   document.getElementById('root')
// );

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<div className="home">
  <App />
  </div>, rootElement);
} else {
  ReactDOM.render(<div className="home">
  <App />
  </div>, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
