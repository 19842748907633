import './App.css';
import {Component} from 'react';
import Footer from './components/footer';
import Home from './components/home';
import Services from './components/services'
import axios from 'axios';
import React from "react";
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {path} from "./wp-config"
import ContactUs from "./components/contactUs"
import WhatWeDo from "./components/what-we-do"
import TestimonialPage from "./components/TestimonialPage"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope, faPhoneAlt} from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link 
} from "react-router-dom";


export default class App extends Component {
    
  state = {
    posts: '',
    isLoaded: '',
    active_link:window.location.pathname.substr(1,)
}
componentDidMount() {
    axios.get(path+"wp/v2/pages/51")
    .then(res => {
        this.setState({
                post: res.data,
                ico:res.data.SiteIcon,
                isLoaded: true
        })
    
            })
        
    .catch(err => console.log(err));
          }
    
    changeHandler = e => {
        this.setState({active_link:e})
        $("#mainNav").removeClass("show");
    }


    render() {
        
        const { ico, post, isLoaded,active_link } = this.state;
        
        
        var favicon = document.getElementById("favicon");
        favicon.setAttribute("href", `${ico}`);
        if(isLoaded){
            let global_data=post.acf.page_51;
            return (
                    <Router >
                        <header className="site-header">
                            <div className="container-fluid">
                                <nav className="navbar navbar-expand-lg navbar-light">
                                    <a href="/" className="header-logo navbar-brand">
                                            {
                                                post.logo &&
                                                <img src={post.logo} className="img-fluid"  alt="Logo"  />
                                            }</a>
                                        <div className="collapse navbar-collapse" id="mainNav">
                                            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                                                <li className={`nav-item ${active_link===''?"active":""}`} onClick={()=> this.changeHandler('')}>
                                                    <Link className={`nav-link`}  to="/">Homes</Link>
                                                </li>
                                                <li className={`nav-item ${active_link==='what-we-do'?"active":""}`} onClick={()=> this.changeHandler('what-we-do')}>
                                                    <Link className="nav-link"  to="/what-we-do">What we do</Link>

                                                </li>
                                                <li className={`nav-item ${active_link==='services'?"active":""}`} onClick={()=> this.changeHandler('services')}>
                                                    <Link className="nav-link" to="/services">Services</Link>

                                                </li>
                                                <li className={`nav-item ${active_link==='testimonial'?"active":""}`} onClick={()=> this.changeHandler('testimonial')}>
                                                    <Link className="nav-link" to="/testimonial">Testimonial</Link>

                                                </li>
                                                <li className={`nav-item ${active_link==='contact'?"active":""}`} onClick={()=> this.changeHandler('contact')}>
                                                    <Link className="nav-link" to="/contact">Contact us</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="header-contacts">
                                            <ul>
                                                <li><a href={"tel:"+global_data.phone}>
                                                    <div>
                                                        <FontAwesomeIcon id="redIcon1" icon={faPhoneAlt} />
                                                    </div>
                                
                                                </a></li>
                                                <li><a href={"mailto:"+global_data.email}>
                                                    <div>
                                                        <FontAwesomeIcon id="redIcon2" icon={faEnvelope} />
                                                    </div>

                                                </a></li>
                                                <li><a href={global_data.linkedin}>
                                                {
                                                    global_data.linkedin_icon && global_data.linkedin_icon.sizes.thumbnail &&
                                                    <img  src={global_data.linkedin_icon.sizes.thumbnail} className="img-fluid" alt="linkedin_icon"/>
                                                }
                                                </a></li>
                                            </ul>
                                        </div>
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                    </nav>
                                    </div>
                            </header>
                        <Switch>
                            <Route path="/services">
                                <Services data={post} /> 
                            </Route> 

                            <Route path="/contact">
                                <ContactUs data={post} />                              
                            </Route> 

                            <Route path="/what-we-do">
                                <WhatWeDo data={post} />                                                                             
                            </Route>

                            <Route path="/testimonial">
                                <TestimonialPage data={post} />                              
                            </Route>

                            <Route path="/">
                                <Home data={post} />                              
                            </Route>
                           
                        </Switch>
                        <section className="contact-info section-sm">
                            <div className="container-fluid">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p className="mb-md-0">
                                            {
                                                global_data.address_icon && global_data.address_icon.sizes.thumbnail &&
                                                <img  src={global_data.address_icon.sizes.thumbnail} className="img-fluid" alt="address_icon"/>
                                            }
                                            {global_data.address}
                                            </p>
                                        </div>
                                        <div className="col-md-4">
                                            <p className="mb-md-0">
                                            {
                                                global_data.phone_icon && global_data.phone_icon.sizes && global_data.phone_icon.sizes.thumbnail &&
                                                <img  src={global_data.phone_icon.sizes.thumbnail} className="img-fluid" alt="phone_icon"/>
                                            }
                                            <a href={"tel:"+global_data.phone}>{global_data.phone}</a></p>
                                        </div>
                                        <div className="col-md-4">
                                            <p className="mb-md-0">
                                            {
                                                global_data.email_icon && global_data.email_icon.sizes.thumbnail &&
                                                <img  src={global_data.email_icon.sizes.thumbnail} className="img-fluid" alt="email_icon"/>
                                            }
                                            <a href={"mailto:"+global_data.email}>{global_data.email}</a> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer data={post}/>
                    </Router>
            )
        }
        return(
            <div className="load">
                <div className="text-center loader">
                    <Loader type="Circles" color="#00BFFF"  height={80} width={80}/>
                </div>
            </div>
 
        )
       
    }  
        
  }
 
 
