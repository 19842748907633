import  {Component} from 'react'
export default class Services extends Component {
  
    constructor(props) {
        super(props)
      
        this.state = {
          data: this.props.data
        }
      }
    render (){
        const post = this.state.data;
        let new_data=post.acf.page_177;
        return(
            <div>
                <div className="container-fluid">
                    <div className=" row hero-banner">
                        {
                            new_data.service_banner && new_data.service_banner.sizes["2048x2048"] &&
                            <img  src={new_data.service_banner.sizes["2048x2048"]} className="img-fluid" alt="service-banner"/>
                        }
                                <div className="overlay">
                                <h1 className="text-uppercase text-md-right">{new_data.service_banner_text}</h1>
                            </div>
                    </div>
                    <section className="section">
                        <div className="container">
                            <div className="row">
                            {new_data.services.map((data,index) => (
                                <div key={index} className="col-md-6 mb-3">
                                    <div className="client-service-col">
                                    <div className="client-service-inner">
                                        <h5>{data.service_title}</h5>
                                        <p>{data.services_text}</p>
                                        <a href={data.service_button_link} className="btn round">{data.serivce_button_text}</a>
                                    </div>
                                    </div>
                                </div>
                            ))}
                            </div>          
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}