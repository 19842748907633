import  {Component} from 'react';

var currentYear= new Date().getFullYear(); 
export default class Footer extends Component {
    render() {
        const post = this.props.data
        return(
            <div className="foot">
                <footer className="copyrights">
                <div className="container">
                <p className="mb-0 small">Copyrights {currentYear}, {post.Site_Title}</p>
                </div>
            </footer>
          </div>
        )
    }
}