import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import  {Component} from 'react';
import { Carousel } from 'react-responsive-carousel';


export default class Testimonials extends Component {
  state = {acf: this.props.data.acf}

  render() {
    // const { acf } = this.props.data;
    let new_data=this.state.acf.page_51;
    return (
      <Carousel autoPlay showThumbs={false} >
      {new_data && new_data.testimonial && new_data.testimonial.map((testimonial,index) => (
        <div key={index} className="item">
            {
                testimonial.testimonial_icon && testimonial.testimonial_icon.sizes.thumbnail &&
                <img className="testmonial_icon" src={testimonial.testimonial_icon.sizes.thumbnail} alt="testmonial_icon"/> 
              }
            <p className="client-review h6">{testimonial.testimonial1}</p>
            {
                testimonial.testimonial_image && testimonial.testimonial_image.url &&
                <img  src={testimonial.testimonial_image.url} className="client-img" alt="testimonial_image"/>
            }
            <p className="client-name">{testimonial.name}</p>
        </div>
      ))}
      </Carousel> 
    );
  }
}