import  {Component} from 'react';
import axios from 'axios';
import {path} from "../wp-config"

export default class Contact extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      firstName: '',
      email: '',
      subject: '',
      message: '',
      rsponse: '',
      res_class:'',
      c_loader:false
    }
  }
    changeHandler = e => {
      this.setState({[e.target.name]: e.target.value})
    }

    submitHandler=e=>{
      this.setState({
        c_loader:true
    })
      let {firstName, email,subject,message}= this.state

      let formData = new FormData()

      formData.set("firstName",firstName)
      formData.set("email", email)
      formData.set("subject", subject)
      formData.set("message", message)

      e.preventDefault()
      axios.post(path+"contact-form-7/v1/contact-forms/103/feedback", 
        formData,
        )
      .then(response =>   this.setState({
        rsponse: response.data,
        firstName:response.data.status==='mail_sent'?'':this.state.firstName, 
        email:response.data.status==='mail_sent'?'':this.state.email,
        subject:response.data.status==='mail_sent'?'':this.state.subject,
        message:response.data.status==='mail_sent'?'':this.state.message,
        res_class:response.data.status==='mail_sent'?' text-success border ':' text-danger border',
        c_loader:false
       }))
      
      .catch(function (response) {
        console.log(response);
      });
    }
    render() {
      const res=this.state.rsponse.message
      let {res_class,c_loader}=this.state;
      let {firstName, email,subject,message}= this.state


        return(
            <form onSubmit={this.submitHandler}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input type="text" value={firstName} className="form-control form-control-c"  name="firstName" onChange={this.changeHandler} placeholder="Full Name*" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input type="email" value={email} className="form-control form-control-c"  name="email" onChange={this.changeHandler} placeholder="Email Address*" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input type="text" value={subject} className="form-control form-control-c "  name="subject"  onChange={this.changeHandler} placeholder="Subject*" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <textarea className="form-control form-control-c" value={message}  name="message" placeholder="Message" onChange={this.changeHandler} cols="3"></textarea>
                </div>
              </div>
              <div className="col-md-12">
             
                  <button type="Submit" className="btn btn-th m-1">Submit</button>
              </div>
              <div className="col-md-12">
                {c_loader && <img className="spinner" src="/spinner.gif" alt="loading-spinner"></img>}
                <div className={`${res_class} contact_message mt-3`}>
                    {res}
                </div>
            </div>
            </div>
            
          </form>

        )
    }
}
// bodyFormData.append("your-email",'gg');

