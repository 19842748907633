import  {React,Component} from 'react'
export default class TestimonialPage extends Component {
 
    render (){
        const post = this.props.data;
        let new_data=post.acf.page_183;
        let f_data=post.acf.page_51;
        return(
            <>
                     <div className="hero-banner">
                        {
                            new_data.testimonial_banner && new_data.testimonial_banner.sizes["2048x2048"] &&
                            <img  src={new_data.testimonial_banner.sizes["2048x2048"]} className="img-fluid" alt="testimonial banner"/>
                        }
                        <div className="overlay">
                            <div className="container-fluid">
                                <h1 className="text-uppercase text-md-right">{new_data.testimonial_banner_title}</h1>
                            </div>
                        </div>
                    </div>
                    <section className="section">
                        <div className="container">
                            <div className="row">
                            {f_data.testimonial && f_data.testimonial.map((testimonial,index) => (
                                <div key={index}  className="col-md-6">
                                    <div className="quote-col">
                                        <div className="quote-col-inner">
                                            {
                                                new_data.testimonial_icon && new_data.testimonial_icon.sizes['thumbnail'] &&
                                                <img src={new_data.testimonial_icon.sizes['thumbnail']} className="quote-icon" alt="" />
                                            }
                                            <p>{testimonial.testimonial1}</p>
                                            <div className="testimonial-by">
                                            <span>
                                            {
                                                testimonial.testimonial_image && testimonial.testimonial_image.url &&
                                                <img  src={testimonial.testimonial_image.url} className="img-fluid" alt="testimonial_image"/>
                                            }
                                            {testimonial.name}
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                      ))}
                            </div>
                            
                        </div>
                    </section>
                    </>


        )
    }
}