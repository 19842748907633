import  {Component} from 'react'
import axios from 'axios';
import {path} from "../wp-config"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelopeOpen,faPhone,faMapMarker} from '@fortawesome/free-solid-svg-icons'

export default class ContactUs extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
          firstName: '',
          email: '',
          subject: '',
          message: '',
          data: this.props.data,
          rsponse: '',
          res_class:'',
          c_loader:false
        }
      }
      changeHandler = e => {
        this.setState({[e.target.name]: e.target.value})
      }

      submitHandler=e=>{
        this.setState({
            c_loader:true
        })
        let {firstName, email,subject,message}= this.state
  
        let formData = new FormData()
  
        formData.set("firstName",firstName)
        formData.set("email", email)
        formData.set("subject", subject)
        formData.set("message", message)
  
        e.preventDefault()
        axios.post(path+"contact-form-7/v1/contact-forms/103/feedback", 
          formData,
          )
          .then(response => 
              this.setState({
            rsponse: response.data,
            firstName:response.data.status==='mail_sent'?'':this.state.firstName, 
            email:response.data.status==='mail_sent'?'':this.state.email,
            subject:response.data.status==='mail_sent'?'':this.state.subject,
            message:response.data.status==='mail_sent'?'':this.state.message,
            res_class:response.data.status==='mail_sent'?' text-success border ':' text-danger border',
            c_loader:false
           })
           )
        .catch(function (response) {
          console.log(response);
        });
      }
    render (){
     const post =this.props.data;
     const res=this.state.rsponse.message
     let new_data=post.acf.page_237;
     let {res_class,c_loader}=this.state;
     
     let {firstName, email,subject,message}= this.state

        return(
            <div>
                <div className="container-fluid">
                    <div className=" row hero-banner">
                        {
                            new_data.contact_us_banner && new_data.contact_us_banner.sizes["2048x2048"] &&
                            <img src={new_data.contact_us_banner.sizes["2048x2048"]} className="img-fluid"  alt="contact banner"  />
                        }
                            <div className="overlay">
                                <h1 className="text-uppercase text-right">{new_data.contact_us_banner_text}</h1>
                            </div>
                    </div>
                </div>
                <section className="section">
                    <div className="container">
                        <div className="row">
                            {
                                post.acf.page_51.address &&
                                <div className="col-md-4">
                                <div className="c-card">
                                <div className="fa">
                                    <FontAwesomeIcon  icon={faMapMarker} />
                                </div>
                                <p>{post.acf.page_51.address}</p>
                                </div>
                            </div>
                            }
                            {
                                post.acf.page_51.phone &&
                            <div className="col-md-4">
                                <div className="c-card">
                                    <div className="fa">
                                        <FontAwesomeIcon  icon={faPhone} />
                                    </div>
                                <p>{post.acf.page_51.phone}</p>
                                </div>
                            </div>
                            }   
                            {
                                post.acf.page_51.email &&
                            <div className="col-md-4">
                                <div className="c-card">
                                <div className="fa">
                                    <FontAwesomeIcon  icon={faEnvelopeOpen} />
                                </div>
                                <p>{post.acf.page_51.email}</p>
                                </div>
                            </div>
                            } 
                            <div className="col-md-12">
                                <div className="contact-col">
                                    <div className="contact-col-inner">
                                        <h3>{new_data.contact_us_form_title_}</h3>
                                        <form onSubmit={this.submitHandler}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="small">Name*</label>
                                                        <input type="text" value={firstName} className="form-control"  onChange={this.changeHandler} name="firstName" placeholder="Name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="small">Email Address*</label>
                                                        <input type="email" value={email} className="form-control"  onChange={this.changeHandler} name="email" placeholder="Email" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="small">Subject*</label>
                                                        <input type="text" value={subject} className="form-control"  onChange={this.changeHandler} name="subject" placeholder="Subject" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="small">Message</label>
                                                        <textarea className="form-control" value={message} name="message"  onChange={this.changeHandler} placeholder="Message" cols="3"></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button type="Submit" className="btn btn-th m-1">Submit</button>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    {c_loader && <img className="spinner" alt="spinner" src="/spinner.gif"></img>}
                                                    <div className={`${res_class} contact_message`}>
                                                        {res}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        
            </div>

        )
    }
}